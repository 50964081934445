<script setup>
import { ref, onMounted, watch } from "vue";
import { useMenuStore } from "@/stores/menus";
import { useInitializeStore } from '@/stores/initialize';
import Cookie from '../src/components/modals/Cookie.vue';
import { usePreSportsStore } from '@/stores/pre_sports';

const initializeStore = useInitializeStore();
const menuStore = useMenuStore();
const device_type = ref(menuStore.isMobile == true ? 'mobile' : 'desktop')

watch(() => device_type.value, (newValue) => {
	if (newValue) {
		menuStore.fetchMenus(device_type.value)
	}
})

onMounted(() => {
	usePreSportsStore().fetchPreSports();

	window.addEventListener("resize", () => {
		initializeStore.updateScreenWidth();
	});

	menuStore.fetchMenus(device_type.value);
})

</script>
<template>
	<RouterView />
	<ModalTarget/>
	<Cookie />
</template>
