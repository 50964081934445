import { defineStore } from 'pinia';
import _ from 'lodash';
import moment from 'moment';
import { useOddsStore } from './odds';

export const useCustomerStore = defineStore('customer', {
	state: () => ({
		customer: null,
		customer_protection: null,
		protection_limits_errors: {},
		auth_token: null,
		balance_visibility: true,
		customer_notification_id: null
	}),
	getters: {
		availableBonuses(state) {
			if (!state.customer) return;
			return state.customer.offers.available_bonus;
		},
		availableFreeBets(state) {
			if (!state.customer) return;
			return state.customer.offers.freebets;
		},
		availableSportsbookMissions(state) {
			if (!state.customer) return;
			return state.customer.offers.sportsbook_missions;
		},
		activeBonuses(state) {
			if (!state.customer) return;
			return state.customer.offers.active_bonus;
		},
		bonusHistory(state) {
			if (!state.customer) return;
			return state.customer.offers.customer_bonus_history;
		},
		freebetsHistory(state) {
			if (!state.customer) return;
			return state.customer.offers.customer_freebets_history;
		},
		isLoggedIn(state) {
			if (!state.customer) return false;
			return true;
		},
		getCustomer(state) {
			return state.customer;
		},
		hasVerifiedEmail(state) {
			if (state.customer) return ((state.customer.privileges.find(privilege => privilege.slug === 'has_verify_email') != undefined) ? true : false);
		},
		customerRealityCheckTimestamp(state) {
			if (!state.customer) return null;

			if (!state.customer.reality_check) return null;

			if (!state.customer.reality_check.customer_protection_configuration_choice) return null;

			if (!state.customer.reality_check.customer_protection_configuration_choice.value) return null;

			return moment().add(state.customer.reality_check.customer_protection_configuration_choice.value, state.customer.reality_check.customer_protection_configuration_choice.type).unix();
		},
		customerTimeLimitsStatus(state) {
			if (!state.customer) return null;

			if (!state.customer.time_limits) return null;

			var time_limits = state.customer.time_limits

			var time_limits_array = {
				'type': null,
				'exceed': false,
				'timestamp': null,
			};

			if (time_limits.duration.remaining_time.monthly != null && time_limits.duration.remaining_time.monthly <= 0) {
				time_limits_array.type = 'monthly';
				time_limits_array.exceed = true;

				return time_limits_array;
			}

			if (time_limits.duration.remaining_time.weekly != null && time_limits.duration.remaining_time.weekly <= 0) {
				time_limits_array.type = 'weekly';
				time_limits_array.exceed = true;

				return time_limits_array;
			}

			if (time_limits.duration.remaining_time.daily != null && time_limits.duration.remaining_time.daily <= 0) {
				time_limits_array.type = 'daily';
				time_limits_array.exceed = true;

				return time_limits_array;
			}

			var timestamps_array = {
				daily: time_limits.duration.remaining_time.daily ? moment().add(time_limits.duration.remaining_time.daily, 'minutes').unix() : null,
				weekly: time_limits.duration.remaining_time.weekly ? moment().add(time_limits.duration.remaining_time.weekly, 'minutes').unix() : null,
				monthly: time_limits.duration.remaining_time.monthly ? moment().add(time_limits.duration.remaining_time.monthly, 'minutes').unix() : null,
			};

			// Sort array values
			timestamps_array = _.fromPairs(_.sortBy(_.toPairs(timestamps_array), 1));

			time_limits_array.type = Object.keys(timestamps_array)[0];
			time_limits_array.timestamp = Object.values(timestamps_array)[0];

			return time_limits_array;
		},
		customerProtectionSection: (state) => (slug) => {
			let section = _.find(state.customer_protection, {'slug': slug});
			return section;
		},
		customerProtectionSectionChoices: (state) => (slug) => {
			let section = _.find(state.customer_protection, {'slug': slug});

			// Group by type
			const groupByType = _.groupBy(section.choices, 'type');

			// Sort arrays
			for (const group_type in groupByType) {
				if (Object.hasOwnProperty.call(groupByType, group_type)) {
					const element = groupByType[group_type];
					groupByType[group_type] = _.sortBy(element)
				}
			}

			// Default sorting
			let sort_list = [
				'no limit',
				'minutes',
				'hours',
				'days',
				'weeks',
				'months',
				'years',
				'infinite',
				'numeric'
			];

			// Denife array to return
			let choices_sorted = [];

			sort_list.forEach(key => {
				// Push No Limit choice
				if (key == 'no limit') {
					let has_no_limit = _.find(section.choices, {'type': 'no limit'} );
					if (has_no_limit != null) choices_sorted.push(has_no_limit);
				}
				// Push Infinite choice
				else if (key == 'infinite') {
					let has_infinite = _.find(section.choices, {'type': 'infinite'} );
					if (has_infinite != null) choices_sorted.push(has_infinite);
				}

				// Sort and push any other choice
				else if (groupByType[key] && groupByType[key].length > 0) {
					Object.keys(groupByType[key]).forEach(choice => {
						choices_sorted.push(groupByType[key][choice]);
					});
				}
			});

			// Return choices
			if (choices_sorted) return choices_sorted;
		},
		hasCustomerProtectionLimits(state) {
			if (state.customer) return ((state.customer.privileges.find(privilege => privilege.slug === 'has_customer_protection_limits') != undefined) ? true : false);
		},
		getProtectionLimitsErrors(state) {
			return state.protection_limits_errors;
		},
		betslipChanges(state) {
			if (!state.isLoggedIn) return 'always_ask';

			return state.getCustomer.customer_settings.betslip_changes;
		},
		freebets(state) {
			if (!state.isLoggedIn) return [];

			return state.getCustomer.offers.freebets;
		},
		verificationDaysLeft(state) {
			if (!state.customer) return null;

			return state.customer.verification_days_left;
		},
		unclaimed_bonuses_length(state) {
			let bonuses_nunmber = Object.keys(state.customer.offers.available_bonus).length;
			let freebet_number = Object.keys(state.customer.offers.freebets).length;
			return bonuses_nunmber + freebet_number
		},
		customerNotifications(state) {
			if (!state.customer) return [];

			return state.customer.notifications;
		},
		customerLastNotification(state) {
			if (!state.customer && state.customer.last_notification) return [];

			return state.customer.last_notification;
		},
		customerNotification: (state) => (id) => {
			let notification = _.find(state.customer.notifications, { 'id': id })
			console.log(notification)
			return notification;
		},
	},
	actions: {
		fetchCustomer() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/me').then((response) => {
				const oddsStore = useOddsStore();
				oddsStore.odds_preview = response.data.data.customer.odds_format;

				this.customer = response.data.data.customer;
				this.customer_protection =  response.data.data.customer_protection;
				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		fetchBalance() {
			// Do not attempt to fetch if not logged in
			if (!this.isLoggedIn) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/account/balance').then((response) => {
				this.customer.balance = response.data.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		emptyCustomer() {
			this.customer = null;
			this.customer_protection = null;
			this.auth_token = null;
			this.password_visibility = false;
		},
		getSlotsPlayerStats() {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/games/stats').then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchProtectionLimits() {
			// Do not attempt to fetch if not logged in
			if (_.isEmpty(this.customer)) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/account/protection-limits').then((response) => {
				if (response.data.deposit_limits) this.customer.deposit_limits = response.data.deposit_limits;
				if (response.data.loss_limits) this.customer.loss_limits = response.data.loss_limits;
				if (response.data.time_limits) this.customer.time_limits = response.data.time_limits;

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},

		// Player Protections - Login Limit
		updateLoginLimit(limit_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/login/limits/update', { id: limit_id }).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Deposit Limit
		updateDepositLimits(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/deposit-limits/update', payload).then((response) => {
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Loss Limit
		updateLossLimits(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/loss-limits/update', payload).then((response) => {
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Bet Limits
		updateBetLimits(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/bet-limits/update', payload).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Reality Check
		updateRealityCheck(reality_check_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/reality-check/update', { id: reality_check_id }).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Self Exclusion
		updateCustomerProtectionSelfExclusion(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/self-exclusion/update', payload).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Cancel Withdraw Request
		updateCustomerProtectionCancelWithdrawRequest(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/cancel-withdraw-request/update', payload).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Time Out
		updateCustomerProtectionTimeOut(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/timeout/update', payload).then((response) => {
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Time Limits
		updateCustomerProtectionTimeLimits(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/time-limits/update', payload).then((response) => {
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Player Protections - Set required limits
		updateCustomerDefaultLimits(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/customer-protection/default-limits/update', payload).then((response) => {
				this.fetchProtectionLimits();

				return Promise.resolve(response);
			}).catch((error) => {
				this.protection_limits_errors = error.response.data;

				return Promise.reject(error.response.data);
			})
		},
		// Customer Notifications - Mark as read
		updateCustomerNotification(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/notifications/' + payload.id + '/update', payload).then((response) => {

				return Promise.resolve(response);
			}).catch((error) => {

				return Promise.reject(error.response.data);
			})
		},
		// Fetch - Customer Notifications
		fetchCustomerNotifications(payload) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/notifications', { params: { page: payload.page } }).then((response) => {
				this.customer.notifications = response.data
				this.customer.unread_notifications_count = response.data.unread_notifications_count

				return Promise.resolve(response);
			}).catch((error) => {

				return Promise.reject(error.response.data);
			})
		},
		updateFavourites(data) {
			this.customer.favourites = data;
		},
		setCustomer(data) {
			this.customer = data
		}
	},
	persist: {
		storage: localStorage,
		paths: ['auth_token'],
	},
})