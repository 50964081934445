<script setup>
/* eslint-disable */
import { onMounted, onBeforeUnmount, watch, ref } from 'vue';
import { useVirtualCourtStore } from '@/stores/virtual_court';
import { useLiveSportsStore } from '@/stores/live_sports';
import { BetSlip } from '@/stores/BetSlip';

const props = defineProps({
	mobile: {
		type: Boolean,
	}
});

const src_url = ref(null);
const virtualCourtStore = useVirtualCourtStore();
const liveSportsStore = useLiveSportsStore();
const betSlipStore = BetSlip();

onBeforeUnmount( () => {
	// virtualCourtStore.emptyVirtualCourt();
});

onMounted(() => {
	if (virtualCourtStore.fixture_in_queue) {
		virtualCourtStore.selectFixtureNew(virtualCourtStore.fixture_in_queue);
		src_url.value = process.env.VUE_APP_VIRTUAL_COURT_BETCOM_URL + '/' + liveSportsStore.liveSportSlug(virtualCourtStore.selectedFixture.sport_id) + '/' + virtualCourtStore.getVirtualCourtLanguageLocale + '/' + virtualCourtStore.selectedFixtureId;
	}

})

watch(() => virtualCourtStore.fixture_in_queue, (newFixture, oldFixture) => {
	virtualCourtStore.selectFixtureNew(newFixture);
	src_url.value = process.env.VUE_APP_VIRTUAL_COURT_BETCOM_URL + '/' + liveSportsStore.liveSportSlug(virtualCourtStore.selectedFixture.sport_id) + '/' + virtualCourtStore.getVirtualCourtLanguageLocale + '/' + virtualCourtStore.selectedFixtureId
})

</script>

<template>
	<div v-if="virtualCourtStore.selectedFixture && virtualCourtStore.isSportEligible" style="max-width:480px; margin:auto;" >
		<iframe id="VirtualLiveDesktop" class="svm-StickyVideoManager_Video svm-StickyVideoManager_Video-sticky" :class="['virtual-live-frame', betSlipStore.betSlipModel=='' ? '' : 'pointer-events-none'] " style="width: 100%; max-width:480px; height: 100%; border: unset; min-height: 470px;" :src="src_url"></iframe>
	</div>
</template>

<style scoped>
.pointer-events-none {
	pointer-events: none;
}
</style>